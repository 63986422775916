.advreassurance {
  font-family: $muli;
  margin-bottom: 0;
  overflow-y: auto;
  padding: 15px 0;
  text-align: center;
  width: 100%;

  @media (min-width: $lg-width) {
    padding: 25px 0;
  }

  .ctn {
    min-width: 100rem;
    padding: 0;
  }

  ul {
    margin-bottom: 0;
  }

  &-item {
    font-size: 1.2rem;
    min-width: 20%;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
    }
  }

  &-link {
    color: inherit;
    display: inline-block;
  }

  &-title {
    display: block;
    font-weight: 700;
    margin: 0.7rem 0 0;

    @media (min-width: $lg-width) {
      margin: 0.6rem 0;
    }
  }

  &-img-ctn {
    @include animated(transform);
    display: inline-block;
    height: 5.2rem;
    overflow: hidden;
    width: 4.8rem;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
}
