.advreassurance-cart {
  .advreassurance-item {
    padding: 1.5rem;
  }

  .advreassurance-img-ctn {
    height: auto;
  }
}

.reassurance-headercart {
  .advreassurance-title {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.3;
    margin: 0;
    max-width: 110px;
    text-align: left;
  }

  .advreassurance-img-ctn {
    height: auto;
    padding-right: 10px;
    text-align: right;
    width: auto;

    span {
      display: inline-block;
      max-width: 50%;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
}
